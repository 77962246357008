import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { emailService } from '../Api/services/emailService';
import { paymentService } from '../Api/services/paynowService';
import { paymentRecordsService } from '../Api/services/paymentRecordsService';
import { ticketOrdersService } from '../Api/services/ticketOrdersService';
import Success from '../assets/images/Successs Icon.svg'
import Failure from '../assets/images/Failure Icon.svg'
import { toast } from 'react-toastify';
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { Event, eventsService } from '../Api/services/eventsService';
import { User } from "@supabase/supabase-js";

const PaymentStatus: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [paymentRecord, setPaymentRecord] = useState<any>(null);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const initialized = useRef(false);
  const [qrValue, setQrValue] = useState<string>('');
  const [event, setEvent] = useState<Event | null>(null);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        const storedRecord = JSON.parse(localStorage.getItem('paymentRecord') || '{}');
        const OrderDetails= JSON.parse(localStorage.getItem('Ticket_Order') || '{}');
        const user= JSON.parse(localStorage.getItem('ticketing_user') || '{}');
        setUser(user);
        console.log(OrderDetails)
        setPaymentRecord(storedRecord);

        if (!storedRecord||!OrderDetails) {
          toast.error('Payment information not found');
          navigate('/events');
          return;
        }

        if (storedRecord.event_id) {
          const eventData = await eventsService.getEventById(storedRecord.event_id);
          if (eventData) {
            setEvent(eventData);
          }
        }
        

        if (storedRecord.poll_url === 'none') {
          console.log(OrderDetails)
          console.log(OrderDetails)
          const updatedPayment = await ticketOrdersService.updateOrderValidity(OrderDetails.orders[0].id);
          if (updatedPayment) {
            setSuccess(true);
            setQrValue(OrderDetails.orders[0].hash);
            
          }
        } else {
          let paymentStatus;
          if (!initialized.current) {
            initialized.current = true;
           paymentStatus = await paymentService.confirmPayment(storedRecord.poll_url);
          }
          initialized.current = false;
          if (paymentStatus?.response?.status === 'paid' || paymentStatus?.status === 'success') {
          //  if (paymentStatus?.response?.success === false) {
            const updatedPayment = await ticketOrdersService.updateOrderValidity(OrderDetails.orders[0].id);
            console.log('updated Data',updatedPayment)
            if (updatedPayment) {                          
              setSuccess(true);
              setQrValue(OrderDetails.orders[0].hash);              
            }
          } else {                      
            setSuccess(false);
          }
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        toast.error('Failed to verify payment status');
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    };

    checkPaymentStatus();
  }, [navigate]);

  const handleDoneClick = () => {
    localStorage.removeItem('paymentRecord');
    localStorage.removeItem('payment');
    navigate('/events');
  };

  const downloadQRCode = async () => {
    const qrCodeElement = document.getElementById("qr-code");
    if (qrCodeElement) {
      try {
        const canvas = await html2canvas(qrCodeElement);
        const dataURL = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "E-ticket.png";
        link.click();
        setIsDownloaded(true);
        toast.success('QR Code downloaded successfully');
      } catch (error) {
        console.error('Error downloading QR code:', error);
        toast.error('Failed to download QR code');
      }
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#0B5B5C]"></div>
        <p className="mt-4 text-gray-600">Processing payment...</p>
      </div>
    );
  }

  return (
   <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
  {success ? (
    <>
      <img src={Success} alt="Success" className="p-2"
      />
      <h1 className="mb-2 text-xl font-semibold text-center text-gray-800">
        Payment Successful!
      </h1>
      <p className="text-center text-gray-600">
        The order confirmation has been sent to {user?.email}.
      </p>
      <div className="mt-6 p-4 w-full max-w-md bg-white rounded shadow-md">
        <div className="flex items-center justify-between">
          <img src={event?.poster_url || 'placeholder.jpg'} alt="Event" className="w-16 h-16 rounded" />
          <div className="ml-4 flex-grow">
            <h2 className="text-lg font-semibold text-gray-800">{event?.name}</h2>
            <p className="text-sm text-gray-600">{event?.date} | {event?.start_time}</p>
          </div>
          <p className="text-lg font-bold text-gray-800">${paymentRecord?.ticket_price+paymentRecord?.service_fee_amount}</p>
        </div>
        {qrValue && (
          <div className="mt-4 flex flex-col items-center">
            <div id="qr-code" className="p-4 bg-gray-100 rounded">
              <QRCode
                value={qrValue}
                bgColor="#FFFFFF"
                fgColor="#000000"
                size={128}
              />
            </div>
            {!isDownloaded && (
              <button
                onClick={downloadQRCode}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Download Ticket Here
              </button>
            )}
          </div>
        )}
      </div>
      <button
        className="mt-6 bg-[#0B5B5C] text-white px-6 py-2 rounded hover:bg-[#0B5B5C]/90 transition-colors"
        onClick={handleDoneClick}
      >
        Done
      </button>
    </>
  ) : (
    <>
     <img src={Failure} alt="Failure" className="p-2"
      />
      <h1 className="mb-2 text-xl font-semibold text-center text-gray-800">
        Payment Unsuccessful!
      </h1>
      <p className="text-center text-gray-600">
        Your order failed to process. Please check if you have enough funds or contact our <a href="/support" className="text-blue-500 underline">support team</a>.
      </p>
      <div className="mt-6 p-4 w-full max-w-md bg-white rounded shadow-md">
        <div className="flex items-center justify-between">
          <img src={event?.poster_url || 'placeholder.jpg'} alt="Event" className="w-16 h-16 rounded" />
          <div className="ml-4 flex-grow">
            <h2 className="text-lg font-semibold text-gray-800">{event?.name}</h2>
            <p className="text-sm text-gray-600">{event?.date} | {event?.start_time}</p>
          </div>
          <p className="text-lg font-bold text-gray-800">${paymentRecord?.amount}</p>
        </div>
      </div>
      <button
        className="mt-6 bg-[#0B5B5C] text-white px-6 py-2 rounded hover:bg-[#0B5B5C]/90 transition-colors"
        onClick={handleDoneClick}
      >
        Done
      </button>
    </>
  )}
</div>

  );
};

export default PaymentStatus;
